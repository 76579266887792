<template>
  <div>
      <vue-header title="我的会员卡"/>
      <div  class="record-tool" v-for="meal in my_list" v-bind:key="meal.id">
        <div class="record-box flex flex-y">
            <div class="record-title">
                {{meal.card_code}}
            </div>
            <div v-if="meal.card_type==1">剩余次数：{{meal.shengyu_count}}次</div>
            <div v-else>剩余元气币：{{meal.card_surplus_price}}</div>
            <div class="flex-1"></div>
            <div>
                <div v-if="meal.status==3">未激活</div>
                <div v-else-if="meal.status==4">已激活</div>
                <div v-else-if="meal.status==5">已到期</div>
            </div>
        </div>
        <div class="record-date flex">
            <div class="flex-1">{{meal.name}}</div>
            <div v-if="meal.status==5">{{timeSubmit(meal.expires_time)}}</div>
        </div>
      </div>
  </div>
</template>
<script>
import vueHeader from '@/components/header';
import { mapActions , mapState } from 'vuex';
import { titleFun } from '@/utils';
//let timer;
export default {
  name: 'card',
  components:{
    vueHeader
  },
  created:function(){
      this.myListMain({});
      titleFun('我的会员卡');
      //window.addEventListener('scroll', this.onSrollFun)
  },
  // destroyed: function () {
  //     window.removeEventListener('scroll', this.onSrollFun, false);
  // },
  computed:{
      ...mapState('my',['my_list']),
  },
  methods:{
      // onSrollFun(){
      //       let _this = this;
      //       clearTimeout(timer);
      //       timer = setTimeout(()=>{
      //           let height = document.documentElement.scrollHeight-document.documentElement.scrollTop-document.documentElement.clientHeight;
      //           //获取滚动条到底部的距离
      //           if(height<1000){
      //               console.log(123,_this);
      //           }
      //       },1000)
      // },
      timeSubmit(value){
        let date = new Date(value);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        return `${y}-${m}-${d}`;
      },
      ...mapActions('my',['myListMain'])
  }
}
</script>

<style lang="scss" scoped>
  @import './card';
</style>